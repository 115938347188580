<template>
  <div
    class="program"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <!-- 列表 -->
    <div class="programlist">
      <!-- 上 -->
      <div class="protop">
        <!-- 知识分类 -->
        <div class="knowledge">
          <span>知识分类</span>
          <el-select v-model="value" clearable placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <!-- 播放次数 -->
        <div class="play">
          <span>播放次数</span>
          <el-input
            placeholder
            v-model="input"
            minlength="60px"
            clearable
          ></el-input>
          <span style="margin-left: 13px; margin-right: 13px">-</span>
          <el-input
            placeholder
            v-model="input2"
            minlength="60px"
            clearable
          ></el-input>
        </div>
        <!-- 关键词 -->
        <div class="antistop">
          <span class="spanguan">关键词</span>
          <el-input placeholder v-model="input3" clearable></el-input>
          <div class="buttons">
            <img
              src="../../assets/images/query.png"
              alt
              style="width: 49px"
              @click="search"
            />
          </div>
        </div>
      </div>
      <!-- table -->
      <div class="tables">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            prop="id"
            label="编号"
            width="120px"
          ></el-table-column>
          <el-table-column
            prop="typeName"
            label="分类"
            width="150px"
          ></el-table-column>
          <el-table-column prop="name" label="节目名称"></el-table-column>
          <el-table-column
            prop="times"
            label="节目时长"
            width="150px"
          ></el-table-column>
          <el-table-column
            prop="createDate"
            label="上传时间"
            width="150px"
          ></el-table-column>
          <el-table-column
            prop="count"
            label="播放次数"
            width="120px"
          ></el-table-column>
          <el-table-column prop="system" label="系统操作">
            <template slot-scope="scope">
              <img
                class="buts"
                src="../../assets/images/for.png"
                @click="edit(scope.$index, scope.row)"
                alt
              />
              <img
                class="buts bu"
                src="../../assets/images/del.png"
                @click="handleDelete(scope.$index, scope.row)"
                alt
              />
              <img
                class="buts"
                src="../../assets/images/hear.png"
                @click="hear(scope.$index, scope.row)"
                alt
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <div
            style="
              margin-left: 25px;
              margin-bottom: 15px;
              font-size: 16px;
              color: #606266;
            "
          >
            <span style="margin-right: 15px"
              >节目数量<span style="color: #92aca7; margin-left: 10px">{{
                totalNum
              }}</span>
              个</span
            >
            <span style="margin-right: 15px"
              >节目时长
              <span style="color: #92aca7; margin-left: 10px">{{
                totalTime
              }}</span>
              分钟</span
            >
            <span style="margin-right: 15px"
              >播放次数
              <span style="color: #92aca7; margin-left: 10px">{{
                totalPlay
              }}</span>
              次</span
            >
          </div>
          <div>
            <el-button
              style="
                margin-left: 25px;
                font-size: 16px;
                color: #606266;
                border-radius: 25px;
                margin-top: 20px;
                background: #d78673;
                color: #ffffff;
              "
              @click="add"
              >添加</el-button
            >
          </div>
        </div>
        <div class="bottom-right">
          <div class="bottom-fen">
            <!-- 分页 -->
            <div class="pages">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :current-page="pageCount"
                layout="total, prev, pager, next"
                :total="totalCount"
              >
              </el-pagination>
              <!-- <el-pagination layout="prev, pager, next" :total="50">
              </el-pagination> -->
            </div>
          </div>
          <div class="buttons">
            <el-button>批量导入</el-button>
            <router-link :to="{ path: './classification' }"
              ><el-button>分类管理</el-button></router-link
            >
            <el-button @click="exportList">数据导出</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 增加按钮的弹框 -->
    <el-dialog
      title="添加"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <el-form
        :model="formInline"
        class="demo-form-inline"
        style="margin-left: 20px"
        label-width="80px"
      >
        <el-form-item label="节目名称">
          <el-input
            v-model="formInline.name"
            placeholder=""
            style="width: 50%"
          ></el-input>
        </el-form-item>
        <el-form-item label="知识分类">
          <el-select v-model="formInline.typeId" clearable placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="链接">
          <el-input
            v-model="formInline.url"
            placeholder=""
            id="conXiao"
            style="width: 50%"
            :disabled="true"
          ></el-input>
          <el-button
            type="primary"
            plain
            @click="pushImage"
            style="margin-left: 20px"
            >上传</el-button
          >
        </el-form-item>
        <el-form-item label="节目时长">
          <el-input
            v-model="formInline.times"
            placeholder=""
            @input="$forceUpdate()"
            style="width: 50%"
            id="one"
            :disabled="true"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addList">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 增加上传音频弹框 -->
    <el-dialog
      title="上传音频"
      :visible.sync="audioFrequency"
      width="30%"
      :before-close="handleCloseaAuFre"
      append-to-body
    >
      <input
        type="file"
        ref="audio"
        accept=".mp3"
        capture="microphone"
        id="pop_audio"
      />
      <!-- <audio id="audioId" controls width="100%"></audio> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="audioFrequency = false">取 消</el-button>
        <el-button type="primary" @click="uploadAudio">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改按钮的弹框 -->
    <el-dialog
      title="修改"
      :visible.sync="dialogVisible1"
      width="40%"
      :before-close="handleClose1"
    >
      <el-form
        :model="formInline"
        class="demo-form-inline"
        style="margin-left: 20px"
        label-width="80px"
      >
        <el-form-item label="节目名称">
          <el-input
            v-model="formInline.name"
            placeholder=""
            style="width: 50%"
          ></el-input>
        </el-form-item>
        <el-form-item label="知识分类">
          <el-select
            v-model="formInline.typeName"
            clearable
            placeholder="请选择"
            @change="xuan"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="链接">
          <el-input
            v-model="formInline.url"
            placeholder=""
            id="conXiaoC"
            style="width: 50%"
            :disabled="true"
          ></el-input>
          <el-button
            type="primary"
            plain
            @click="pushImage1"
            style="margin-left: 20px"
            >上传</el-button
          >
        </el-form-item>
        <el-form-item label="节目时长">
          <el-input
            v-model="formInline.times"
            placeholder=""
            @input="$forceUpdate()"
            style="width: 50%"
            id="two"
            :disabled="true"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="upList">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改上传音频弹框 -->
    <el-dialog
      title="上传音频"
      :visible.sync="audioChange"
      width="30%"
      :before-close="handleCloseaAuC"
      append-to-body
    >
      <input
        type="file"
        ref="audio"
        accept=".mp3"
        capture="microphone"
        id="pop_audio"
      />
      <!-- <audio id="audioId" controls width="100%"></audio> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="audioChange = false">取 消</el-button>
        <el-button type="primary" @click="uploadAudio1">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 录音的弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleYin"
      :before-close="handleDialogClose"
      width="30%"
    >
      <span v-if="isShow">无通话录音</span>
      <audio
        ref="audioLing"
        name="audioOne"
        controls
        controlslist="nodownload"
        :src="yinImage"
        @input="$forceUpdate()"
        @change="$forceUpdate()"
        type="audio/mp3"
        v-if="isShow1"
      ></audio>
    </el-dialog>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
import * as qiniu from "qiniu-js";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      dialogVisible1: false,
      isShow: false,
      isShow1: true,
      dialogVisibleYin: false,
      yinImage: "",
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      options: [],
      value: "",
      input: "",
      input2: "",
      input3: "",
      tableData: [],
      dialogVisible: false,
      formInline: {},
      token: "",
      accessKey: "",
      bucket: "",
      secretKey: "",
      prefix: "",
      audioFrequency: false,
      id: "",
      tokenY: "",
      accessKeyY: "",
      bucketY: "",
      secretKeyY: "",
      prefixY: "",
      audioChange: false,
      changeId: null,
      cross: false,
      totalNum: "",
      totalTime: "",
      totalPlay: "",
      shichang: null,
      shichang1: null,
    };
  },
  created() {
    this.getList();
    this.tetList();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    xuan(val) {
      console.log("val:", val);
      this.formInline.typeId = val;
    },
    upList() {
      let yin = JSON.parse(localStorage.getItem("changeAudio"));
      let aa = JSON.parse(localStorage.getItem("knowTime1"));
      this.formInline.url = yin;
      this.formInline.times = aa;
      if (this.cross == true) {
        apiShout
          .upKnow({
            id: this.changeId,
            name: this.formInline.name,
            typeId: this.formInline.typeId,
            url: this.formInline.url,
            times: this.formInline.times,
          })
          .then((res) => {
            console.log("修改：", res.data);
            if (res.data.code == 200) {
              this.dialogVisible1 = false;
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.getList();
              this.cross = false;
              this.formInline = {};
            } else {
              this.$message.error(res.data.msg);
            }
          });
      } else {
        apiShout
          .upKnow({
            id: this.changeId,
            name: this.formInline.name,
            typeId: this.formInline.typeId,
          })
          .then((res) => {
            console.log("修改：", res.data);
            if (res.data.code == 200) {
              this.dialogVisible1 = false;
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.getList();
              this.formInline = {};
            } else {
              this.$message.error(res.data.msg);
            }
          });
      }
    },
    add() {
      this.dialogVisible = true;
      this.formInline = {};
    },
    addList() {
      let yin = JSON.parse(localStorage.getItem("knowAudio"));
      let aa = JSON.parse(localStorage.getItem("knowTime"));
      this.formInline.url = yin;
      this.formInline.times = aa;
      if (this.formInline.url == "" || this.formInline.url == null) {
        this.$message.error("链接不能为空！");
        return false;
      }
      apiShout
        .inKnow({
          name: this.formInline.name,
          typeId: this.formInline.typeId,
          url: this.formInline.url,
          times: this.formInline.times,
        })
        .then((res) => {
          console.log("添加：", res.data);
          if (res.data.code == 200) {
            this.dialogVisible = false;
            this.$message({
              message: "成功添加",
              type: "success",
            });
            this.getList();
            this.formInline = {};
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    pushImage() {
      this.audioFrequency = true;
      //获取七牛云存储配置
      let that = this;
      that.token = JSON.parse(sessionStorage.getItem("token"));
      //console.log("token", that.token);
      api.getQiuNiu({ type: "knowledge" }).then((res) => {
        console.log("获取七牛云存储配置：", res.data.result);
        this.token = res.data.result.token;
        this.accessKey = res.data.result.accessKey;
        this.bucket = res.data.result.bucket;
        this.secretKey = res.data.result.secretKey;
        this.prefix = res.data.result.prefix;
      });
    },
    //增加上传音频
    uploadAudio() {
      this.audioFrequency = false;
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      //清空图片
      // this.fileList = [];
      console.log("===========", this.$refs.audio.files);
      this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
      //获取本地
      // let bIm=JSON.parse(localStorage.getItem('realBackImage'));
      this.id = this.userinfo.id;
      if (this.$refs.audio.files.length === 0) {
        alert("请选择文件");
      }
      let file = this.$refs.audio.files[0];
      let duration = 0;
      const url = URL.createObjectURL(file);
      const filelement = new Audio(url);
      filelement.addEventListener("loadedmetadata", function (event) {
        console.log(event);
        duration = filelement.duration; // 得到视频或音频的时长，单位秒
        this.shichang = Math.floor(duration);
        localStorage.setItem("knowTime", JSON.stringify(this.shichang));
        document.getElementById("one").placeholder = this.shichang;
        // console.log("视频或音频的时长，单位秒", this.shichang);
      });
      let shijiancuo = new Date().getTime();
      let token = this.token;
      //处理文件拓展名
      let indexName = file.name.lastIndexOf(".");
      let ming = file.name.slice(indexName);
      const putExtra = {};
      const config = {};
      //console.log('用户：',this.id);
      const observable = qiniu.upload(
        file,
        this.prefix + this.id + shijiancuo + ming,
        token,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          console.log("hash:", res.hash, res.key);
          localStorage.setItem("knowAudio", JSON.stringify(res.key));
          loading.close();
          document.getElementById("conXiao").placeholder = res.key;
        },
      };
      observable.subscribe(observer); // 上传开始
    },
    pushImage1() {
      this.audioChange = true;
      //获取七牛云存储配置
      let that = this;
      that.token = JSON.parse(sessionStorage.getItem("token"));
      //console.log("token", that.token);
      api.getQiuNiu({ type: "knowledge" }).then((res) => {
        console.log("获取七牛云存储配置：", res.data.result);
        this.tokenY = res.data.result.token;
        this.accessKeyY = res.data.result.accessKey;
        this.bucketY = res.data.result.bucket;
        this.secretKeyY = res.data.result.secretKey;
        this.prefixY = res.data.result.prefix;
      });
    },
    //修改确定上传音频
    uploadAudio1() {
      this.audioChange = false;
      this.cross = true;
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      //清空图片
      // this.fileList = [];
      // console.log("===========", this.$refs.upload.uploadFiles[0]);
      this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
      //获取本地
      // let bIm=JSON.parse(localStorage.getItem('advertBackImage1'));
      this.id = this.userinfo.id;
      if (this.$refs.audio.files.length === 0) {
        alert("请选择文件");
      }
      let file = this.$refs.audio.files[0];
      let duration = 0;
      const url = URL.createObjectURL(file);
      const filelement = new Audio(url);
      filelement.addEventListener("loadedmetadata", function (event) {
        console.log(event);
        duration = filelement.duration; // 得到视频或音频的时长，单位秒
        this.shichang1 = Math.floor(duration);
        localStorage.setItem("knowTime1", JSON.stringify(this.shichang1));
        document.getElementById("two").value = this.shichang1;
        console.log("视频或音频的时长，单位秒", this.shichang1);
      });
      let shijiancuo = new Date().getTime();
      let token = this.tokenY;
      //处理文件拓展名
      let indexName = file.name.lastIndexOf(".");
      let ming = file.name.slice(indexName);
      const putExtra = {};
      const config = {};
      //console.log('用户：',this.id);
      const observable = qiniu.upload(
        file,
        this.prefixY + this.id + shijiancuo + ming,
        token,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          console.log("hash:", res.hash, res.key);
          localStorage.setItem("changeAudio", JSON.stringify(res.key));
          // localStorage.setItem("realAudio1", JSON.stringify(res.key));
          // this.photoUrl = res.key;
          loading.close();
          document.getElementById("conXiaoC").value = res.key;
          // console.log("====", document.getElementById("conXiaoC"));
          // document.getElementById("addAdvertImg").src=bIm;
          //存本地
          // alert('上传成功');
        },
      };
      observable.subscribe(observer); // 上传开始
    },
    getList() {
      apiShout
        .knowList({ pageSize: this.pageSize, pageCount: this.pageCount })
        .then((res) => {
          console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.totalNum = res.data.result.totalNum;
            this.totalPlay = res.data.result.totalPlay;
            this.totalTime = res.data.result.totalTime;
            this.tableData.forEach((ele) => {
              if (ele.times == null) {
                ele.times = "";
              } else {
                let ss = ele.times;
                let result = parseInt(ss);

                let h =
                  Math.floor(result / 3600) < 10
                    ? "0" + Math.floor(result / 3600)
                    : Math.floor(result / 3600);

                let m =
                  Math.floor((result / 60) % 60) < 10
                    ? "0" + Math.floor((result / 60) % 60)
                    : Math.floor((result / 60) % 60);

                let s =
                  Math.floor(result % 60) < 10
                    ? "0" + Math.floor(result % 60)
                    : Math.floor(result % 60);

                ele.times = `${h}:` + `${m}:` + `${s}`;
              }
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    search() {
      apiShout
        .knowList({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          max: this.input2,
          min: this.input,
          name: this.input3,
          typeId: this.value,
        })
        .then((res) => {
          console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.totalNum = res.data.result.totalNum;
            this.totalPlay = res.data.result.totalPlay;
            this.totalTime = res.data.result.totalTime;
            this.tableData.forEach((ele) => {
              if (ele.times == null) {
                ele.times = "";
              } else {
                let ss = ele.times;
                let result = parseInt(ss);

                let h =
                  Math.floor(result / 3600) < 10
                    ? "0" + Math.floor(result / 3600)
                    : Math.floor(result / 3600);

                let m =
                  Math.floor((result / 60) % 60) < 10
                    ? "0" + Math.floor((result / 60) % 60)
                    : Math.floor((result / 60) % 60);

                let s =
                  Math.floor(result % 60) < 10
                    ? "0" + Math.floor(result % 60)
                    : Math.floor(result % 60);

                ele.times = `${h}:` + `${m}:` + `${s}`;
              }
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    tetList() {
      apiShout.typeList({}).then((res) => {
        // console.log("列表：", res.data);
        if (res.data.code == 200) {
          this.options = res.data.result;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //导出
    exportList() {
      let that = this;
      that
        .$http({
          url: "/too/center/knowledge/exportHealthKnowledgeList",
          params: {
            max: this.input2,
            min: this.input,
            name: this.input3,
            typeId: this.value,
          },
          method: "get",
          responseType: "blob",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          // console.log(res,"查询")
          console.log("导出数据：", res.data);
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
            crossOrigin: "Anonymous",
          });
          const a = document.createElement("a");
          let date = new Date();
          var year = date.getFullYear();
          var month =
            date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1;
          var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var minutes =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          var seconds =
            date.getSeconds() < 10
              ? "0" + date.getSeconds()
              : date.getSeconds();
          // 拼接
          let ds =
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;
          document.body.appendChild(a);
          a.style.display = "none";
          // 使用获取到的blob对象创建的url
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          // 指定下载的文件名
          a.download = "养生知识" + ds + ".xls";
          a.click();
          document.body.removeChild(a);
          // 移除blob对象的url
          window.URL.revokeObjectURL(url);
        });
    },
    handleSizeChange(size) {
      //console.log("size:", size);
      this.pageSize = size;
      this.getList();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      //console.log("pageCount:", pageCount);
      this.pageCount = pageCount;
      this.getList();
    },
    edit(index, row) {
      console.log(index, row, "查询row");
      this.dialogVisible1 = true;
      this.changeId = row.id;
      this.formInline = { ...row };
    },
    handleDelete(index, row) {
      console.log(index, row, "查询row信息");
      this.$confirm("是否确定删除此信息?", "确认信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          apiShout.deKnow({ id: row.id }).then((res) => {
            // console.log("删除：", res.data);
            if (res.data.code == 200) {
              this.$message({
                message: "成功删除",
                type: "success",
              });
              this.getList();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleCloseaAuFre() {
      this.audioFrequency = false;
    },
    handleClose1() {
      this.dialogVisible1 = false;
    },
    handleCloseaAuC() {
      this.audioChange = false;
    },
    handleDialogClose() {
      console.log("关闭了");
      this.dialogVisibleYin = false;
      this.$refs.audioLing.pause();
    },
    //录音的播放
    hear(index, row) {
      console.log("======", index, row);
      this.dialogVisibleYin = true;
      if (row.url == null) {
        this.isShow = true;
        this.isShow1 = false;
      } else {
        this.isShow1 = true;
        this.isShow = false;
        this.yinImage = row.url;
      }
    },
  },
};
</script>

<style lang='less' scoped>
.bottom {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
}
.bottom-right {
  float: right;
  margin-right: 20px;
}
.bottom-left {
  position: relative;
  //   display: flex;
}
.bottom-fen {
  display: flex;
  flex-wrap: nowrap;
  align-content: end;
  justify-content: end;
}
/deep/.el-input__inner {
  height: 33px;
  line-height: 33px;
}
/deep/.el-input__icon {
  line-height: 33px;
}
/deep/.el-table th.el-table__cell > .cell {
  font-size: 14px;
  color: #909399;
}
/deep/.el-table td.el-table__cell div {
  color: #606266;
  font-size: 14px;
}
/deep/.el-table .el-table__cell {
  text-align: center;
}
.program {
  position: absolute;
  height: 100%;
  width: 100%;
  // 列表
  .programlist {
    margin-left: 25px;
    margin-right: 40px;
    // 上
    .protop {
      position: relative;
      margin-top: 25px;
      height: auto;
      margin-right: 40px;
      display: flex;
      align-items: center;
      span {
        margin-right: 10px;
        color: #909399;
        font-size: 14px;
        font-weight: bold;
      }
      // input框
      .el-input__inner {
        border-radius: 0;
        border: 1px solid #e1e1e1;
      }
      // 知识分类
      .knowledge {
        position: absolute;
        top: 3px;
        .el-input {
          width: 190px;
          margin-right: 0px;
        }
      }
      // 播放次数
      .play {
        position: absolute;
        left: 300px;
        top: 3px;
        .el-input {
          width: 134px;
        }
      }
      // 关键词
      .antistop {
        position: absolute;
        left: 700px;
        top: 3px;
        display: flex;
        align-items: center;
        .spanguan {
        }
        .el-input {
          width: 230px;
        }
        .buttons {
          margin-left: 14px;
        }
      }
    }
    // table
    .tables {
      margin-top: 60px;
      .el-table::before {
        height: 0;
      }
      .el-table {
        td,
        th.is-leaf {
          border: none !important;
        }
        tr th {
          font-size: 19px;
          color: #a8a8a8;
          text-align: center;
        }
        tr td {
          text-align: center;
          font-size: 17px;
          color: #a8a8a8;
        }
      }
      .buts {
        width: 36px;
        height: 27px;
      }
      .bu {
        margin-right: 20px;
        margin-left: 20px;
      }
    }
    // 总结
    .total {
      display: flex;
      justify-content: space-between;
      margin-right: 0px;
      margin-top: 30px;
      margin-left: 34px;
      .right {
        display: flex;
        align-items: center;
        .el-pagination {
          padding: 0;
          .el-pager,
          .el-pager li {
            margin-top: 2px;
            color: #afafaf;
            min-width: 50px;
            font-size: 17px;
            font-weight: normal;
          }
          .el-pager li.active {
            color: #d78673;
          }
          .el-pager li:hover {
            color: #d78673;
          }
          img {
            display: inline-block;
          }
          .lefts {
            margin-right: 25px;
          }
          .rights {
            float: right;
            margin-left: 25px;
          }
        }
      }
      p {
        font-size: 15px;
        display: inline-block;
        margin-right: 36px;
        color: #606266;
      }
      .spanto {
        color: #92aca7;
        margin-left: 17px;
        margin-right: 12px;
      }
    }
    // 按钮
    .buttons {
      display: flex;
      justify-content: flex-end;
      .el-button {
        float: right;
        width: 130px;
        height: 40px;
        line-height: 40px;
        border-radius: 25px;
        margin-top: 20px;
        background: #d78673;
        color: #ffffff;
        font-size: 16px;
        padding: 0px;
      }
    }
  }
  /deep/.el-table td,
  .el-table th.is-leaf {
    border-bottom: none !important;
  }
  /deep/.el-table .cell {
    text-align: center !important;
  }
  /deep/.el-table thead tr th.is-leaf {
    border: none;
    border-right: none;
  }
  /deep/ .el-form-item__label {
    color: #909399;
  }
  /deep/ .el-dialog__body {
    padding: 10px;
  }
}
</style>